<template>
  <Item
    :item="item"
    :parent="parent"
    class="group items-start h-full"
    :class="{
      'text-left': horizontal,
      'pl-14': horizontal && !hasBackground && itemIcon,
      'pl-20': horizontal && hasBackground && itemIcon,
    }"
  >
    <Icon
      v-if="itemIcon"
      ref="icon"
      :item="itemIcon"
      :parent="item"
      :is-absolute="horizontal"
      :class="{
        'mr-4 left-0': horizontal,
        'ml-6': hasBackground && horizontal,
        'mb-2': itemIcon.props.bgColorBool,
      }"
    />

    <Content
      v-if="itemHeadline"
      :item="itemHeadline"
      :parent="item"
      class="pt-1"
      :class="{
        'mb-2': itemBodyText,
      }"
    />

    <Content
      v-if="itemBodyText"
      :item="itemBodyText"
      :parent="item"
      class="col-start-2 mb-auto"
    />
  </Item>
</template>

<script>
import { get } from 'vuex-pathify'
import itemMixin from '@/mixins/item'

export default {
  name: 'Feature',

  components: {
    Item: () => import('@/components/Item'),
    Content: () => import('@/components/Items/Content'),
    Icon: () => import('@/components/Items/Icon'),
  },

  mixins: [itemMixin],

  props: {
    index: {
      type: Number,
      default: 0,
    },

    horizontal: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isAdminMode: get('isAdminMode'),

    itemIcon() {
      return this.item.items.find(item => item.name === 'Icon')
    },

    itemHeadline() {
      return this.item.items.find(item => item.name === 'Headline')
    },

    itemBodyText() {
      return this.item.items.find(item => item.name === 'BodyText')
    },
  },
}
</script>
