var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Item',{staticClass:"group items-start h-full",class:{
    'text-left': _vm.horizontal,
    'pl-14': _vm.horizontal && !_vm.hasBackground && _vm.itemIcon,
    'pl-20': _vm.horizontal && _vm.hasBackground && _vm.itemIcon,
  },attrs:{"item":_vm.item,"parent":_vm.parent}},[(_vm.itemIcon)?_c('Icon',{ref:"icon",class:{
      'mr-4 left-0': _vm.horizontal,
      'ml-6': _vm.hasBackground && _vm.horizontal,
      'mb-2': _vm.itemIcon.props.bgColorBool,
    },attrs:{"item":_vm.itemIcon,"parent":_vm.item,"is-absolute":_vm.horizontal}}):_vm._e(),_vm._v(" "),(_vm.itemHeadline)?_c('Content',{staticClass:"pt-1",class:{
      'mb-2': _vm.itemBodyText,
    },attrs:{"item":_vm.itemHeadline,"parent":_vm.item}}):_vm._e(),_vm._v(" "),(_vm.itemBodyText)?_c('Content',{staticClass:"col-start-2 mb-auto",attrs:{"item":_vm.itemBodyText,"parent":_vm.item}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }